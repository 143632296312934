<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="订单类型">
          <el-select class="item-choose" v-model="dataForm.orderType" size="small" filterable clearable
                     placeholder="请选择">
            <el-option
              v-for="item in dictTypeMap.orderType"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="处理状态">
          <el-select class="item-choose" v-model="dataForm.status" size="small" filterable clearable>
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称" v-if="newAuth('abnormal:manager:findAll')">
          <el-input v-model="dataForm.customerName" placeholder="请输入客户名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="进仓单号">
          <el-input v-model="dataForm.inWarehouseNo" placeholder="请输入进仓单号" clearable></el-input>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            size="small"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList"  :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"  border size="small" v-loading="dataListLoading" style="width: 100%;" height="70vh" :span-method="arraySpanMethod">
        <el-table-column label="订单环节" prop="driverPhone" align="left" fixed="left" width="60">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.status == 0" size="small" type="danger">未提交</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 1" size="small" type="warning">待审核</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 2" size="small" type="warning">调度中</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 3" size="small">已调度</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 4" size="small">待出车</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 5" size="small" type="success">已出车</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 6" size="small" type="success">已到达</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 7" size="small" type="success">已装货</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 15" size="small" type="success">卸货到场</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 8" size="small" type="success">已完成</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 9" size="small" type="danger">异常结束</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 10" size="small" type="danger">审核拒绝</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 11" size="small" type="danger">订单退回</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 12" size="small" type="info">已取消</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="客户名称" prop="orderInfo.customerName" align="left" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货日期" prop="takeGoodsTime" align="left" width="105" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
          </template>
        </el-table-column>
        <el-table-column label="订单类型" :formatter="orderType" prop="orderInfo.orderType" align="left" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="指派车型" prop="vehiclePlan.vehicleTypeName" align="left" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="车牌号" prop="vehiclePlan.vehiclePlate" align="left" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机姓名" prop="vehiclePlan.driverName" align="left" width="65" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机电话" prop="vehiclePlan.driverPhone" align="left" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="所属车队" prop="vehiclePlan.motorcadeName" align="left" width="75" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" align="left" width="105" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="报关方式" :formatter="entranceType" prop="orderInfo.entranceType" width="80" :show-overflow-tooltip="true">
        </el-table-column>
<!--        <el-table-column label="提货方" prop="factoryName" width="80" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column label="提货地址" prop="fullAddress" width="180" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column label="卸货方" prop="giveAddressInfo.factoryName" width="75" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column label="卸货地址" prop="giveAddressInfo.fullAddress" width="180" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
        <el-table-column label="客户联系人" prop="orderInfo.customerContactName" align="left" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客户联系电话" prop="orderInfo.customerContactPhone" align="left" width="85" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="异常类型" prop="typeName" align="left" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="异常说明" prop="explainDesc" align="left" width="105" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="上报时间" prop="createTime" align="left" width="105" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="异常附件" prop="pieceNum" align="left" width="60">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row)" style="color: blue;cursor:pointer">查 看</span>
          </template>
        </el-table-column>
        <el-table-column label="异常处理说明" prop="solution" align="left" width="105" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单编号" prop="orderInfo.orderNo" align="left" width="105" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="status" align="center" label="异常状态" fixed="right" width="60" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 1" size="small">已处理</el-tag>
            <el-tag v-else size="small" type="danger">未处理</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" header-align="center" align="center" label="操作" width="40">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="newAuth('abnormal:manager:find')" type="text" size="small" @click="detail(scope.row, 'view')">详情
              </el-button>
              <el-button v-if="newAuth('abnormal:manager:update') && scope.row.status !== 1" type="text" size="small" @click="detail(scope.row, 'update')">处理
              </el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗 -->
    <abnormal-Detail ref="detail" @refreshDataList="getDataList"></abnormal-Detail>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import abnormalDetail from './abnormalDetail/abnormal-detail'
import viewImage from '@/views/commonPop/viewImage'
export default {
  data () {
    return {
      dataForm: {
        inWarehouseNo: null,
        customerId: null,
        orderType: null,
        status: 2,
        customerName: null,
        takeGoodsTime: null
      },
      statusList: [
        {
          label: '已处理',
          value: 1
        },
        {
          label: '未处理',
          value: 2
        }
      ],
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      getIndex: -1,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      abnormalTypeList: [6020]
    }
  },
  components: {
    abnormalDetail,
    viewImage
  },
  created () {
    this.setDefaultDate()
  },
  activated () {
    this.getDataList()
  },
  computed: {
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    },
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex=row.index
    },
    /**
     * 点击表格变色end
     */
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // if (columnIndex === 0) {
      //   if (rowIndex % 2 === 0) {
      //     return {
      //       rowspan: 2,
      //       colspan: 1
      //     };
      //   } else {
      //     return {
      //       rowspan: 0,
      //       colspan: 0
      //     }
      //   }
      // }
    },
    // 查看照片
    viewPhoto (row) {
      this.$refs.viewPhoto.init(row.id, this.abnormalTypeList)
    },
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24)
      end.setTime(end.getTime() + 3600 * 1000 * 24)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      if (!this.newAuth('abnormal:manager:findAll')) {
        this.dataForm.customerId = this.currentUser.customerId
      }
      this.$http({
        url: this.$http.adornUrl('/orderException/findList'),
        method: 'GET',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'status': this.dataForm.status,
          'orderInfo.inWarehouseNo': this.dataForm.inWarehouseNo,
          'orderInfo.orderType': this.dataForm.orderType,
          'orderInfo.customerName': this.dataForm.customerName,
          'orderInfo.customerId': this.dataForm.customerId,
          'orderInfo.takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'orderInfo.takeGoodsEndDate': timeParam.takeGoodsEndDate
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 详情
    detail (row, todo) {
      this.$nextTick(() => {
        this.$refs.detail.init(row, todo)
      })
    }
  }
}
</script>
